<template>
<!-- 脓毒症 -->
  <div style="margin: -14px -15px;overflow: hidden">
    <!--    病例审核管理-->
    <!--    <div class="review-title">病例审核管理</div>-->
    <!--    医生信息-->
    <div class="review-doc">
      <div class="review-doc-title">医生信息</div>
      <div class="review-doc-content">
        <div class="review-doc-content-font">姓名 : {{dataForm.memberName}}</div>
        <div class="review-doc-content-font">医院 : {{dataForm.memberHospitalName}}</div>
        <div class="review-doc-content-font">上传时间 : {{dataForm.created_time}}</div>
      </div>
    </div>
    <!--    患者建档信息-->
    <div class="review-huan">
      <div class="review-huan-title">
        <div class="review-huan-title-huanzhe">患者建档信息</div>
        <div>
          <a-button type="primary" @click="preservation">保存患者信息</a-button>
        </div>
      </div>
      <div class="review-huan-content">
        <div class="review-huan-content-font">
          <div>性别 : </div>
          <div style="margin-left: 15px">
            <a-radio-group
                v-model="dataForm.patientSex"
                :options="SexPerson"
                @change="onChange" />
          </div>
        </div>
        <div class="review-huan-content-font-two">
          <!--          <div>年龄 :</div><div> {{caseDetail.patientAge}}</div>-->
          <div>年龄 :{{dataForm.patientAge}}</div>
          <div style="margin-left: 5px">年龄</div>
          <div style="margin-left: 15px">
            <a-date-picker
                placeholder="请选择性出生日期"
                :format="dateFormatList"
                @change="ageChange" /></div>
        </div>
      </div>
    </div>
    <!--    中间上传照片部分-->
    <div class="review-content">
      <!--      图片信息-->
      <div class="review-content-left">
        <div class="review-content-left-title">图片信息
          <a-button
              @click="refreshBtn"
              type="dashed" style="margin-left: 50%">刷新</a-button>
          <a-button
              @click="captchaBtn"
              type="primary" style="margin-left: 20px">打码</a-button>
        </div>
        <div class="review-content-left-font" style="margin-top: 30px">病例图片 ({{lenghtImage}}张)</div>
        <div style="height:1500px;width:100%;overflow:auto;">
          <div
              style="width: 100%"
              v-for="(item,index) in  dataForm.coding_image_list"
          >
            <img
                style="width: 95%;height: auto;margin: 10px 10px"
                :key="index"
                :src="dataForm.coding_image_list[index]"
            />
          </div>
        </div>
      </div>
      <!--      问卷信息-->
      <div class="review-content-right">
        <div class="review-content-right-title">问卷信息</div>
        <div style="width: 80%" v-show="dataForm.reject_reason">
          <div class="review-content-right-title-title">驳回原因</div>
          <div class="review-content-right-person" style="margin-top: 15px">
            {{dataForm.reject_reason}}
          </div>
        </div>
        <div style="width: 80%">
          <div class="review-content-right-title-title">患者基本信息</div>
          <div class="review-content-right-person" style="margin-top: 15px">
            <div class="review-content-right-person-sex">
              住院编号 : <a-input
                class="inputWitch"
                v-model="dataForm.template_content.resident_admit_note.patient_number"
            />
            </div>
            <a-button style="margin-right: 10px" @click="examineBtn(dataForm.id,dataForm.template_content.resident_admit_note.patient_number)" type="primary">检查</a-button>
            <div class="review-content-right-person-sex">
              性别 : <a-radio-group
                v-model="dataForm.template_content.resident_admit_note.sex"
                :options="quest_Sex" />
            </div>
          </div>
          <div class="review-content-right-person" style="margin-top: 15px">
            <div class="review-content-right-person-name">
              年龄 : <a-input
                class="inputWitch"
                v-model="dataForm.template_content.resident_admit_note.age"
            />
            </div>
            <div style="margin-left: -30px">
              患者体重 : <a-input
                class="inputWitch"
                v-model="dataForm.template_content.resident_admit_note.height"
                placeholder="请输入体重"
            />
            </div>
          </div>
          <div class="review-content-right-person" style="margin-top: 15px">
            入院时间 :
            <a-date-picker
                :allowClear="false"
                v-model="dataForm.template_content.resident_admit_note.admission_time"
                style="width: 300px;margin-left: 10px"
                placeholder="Select month"
                @change="hospitalizedChange" />
          </div>
          <div class="review-content-right-person">
            <div style="margin-left: 3%">既往史 :</div>
            <div style="width: 78%;margin-left: 5px">
              <a-textarea
                  v-model="dataForm.template_content.resident_admit_note.previous_hostory"
                  style="width: 100%"
                  placeholder=""
                  :auto-size="{ minRows: 3, maxRows: 5 }"
              />
            </div>
          </div>
          <div class="review-content-right-person">
            <div>诊断结果 :</div>
            <div style="width: 78%;margin-left: 5px">
              <a-input
                  v-model="dataForm.template_content.resident_admit_note.diagnostic_results"
                  style="width: 100%"
                  placeholder=""
              />
            </div>
          </div>
        </div>
        <div style="display: flex;width: 100%;margin-top: 20px">
          <div style="margin-left: 30px;display: flex">
            脓毒症qSOFA是否予以评估 :
            <a-radio-group
              style="margin-left: 20px"
              v-model="dataForm.template_content.resident_admit_note.ndz_qsofa"
              :options="quest_ndz_qsofa" />
          </div>
        </div>
        <div style="width: 100%;margin-top: 20px">
          <div style="margin-left: 50px;">
<!--            ndz_qsofa_detail 转换成字符串形式。josn("; ")   v-model="partition.str_ndz_qsofa_detail"    -->
            <a-checkbox-group
                v-model="dataForm.template_content.resident_admit_note.ndz_qsofa_detail"
                style="width: 350px;margin-left: 80px"
                @change="checkboxChange">
              <a-row>
                <a-col :span="24" style="margin-top: 10px;">
                  <a-checkbox value="呼吸频率≥22次/分">
                    呼吸频率≥22次/分
                  </a-checkbox>
                </a-col>
                <a-col :span="24" style="margin-top: 10px;">
                  <a-checkbox value="意识改变">
                    意识改变
                  </a-checkbox>
                </a-col>
                <a-col :span="24" style="margin-top: 10px;">
                  <a-checkbox value="收缩压≤100 mmHg)">
                    收缩压≤100 mmHg)
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </div>
        </div>
        <div style="display: flex;width: 100%;margin-top: 20px">
          <div style="margin-left: 30px;display: flex">
            SOFA是否予以评估 :<a-radio-group
              style="margin-left: 20px"
              v-model="dataForm.template_content.resident_admit_note.sofa"
              :options="quest_sofa" />
          </div>
        </div>
        <div style="display: flex;width: 100%;margin-top: 20px">
          <div style="margin-left: 30px;display: flex">
            <a-input
                v-model="dataForm.template_content.resident_admit_note.sofa_deatil"
                placeholder="评分较基线上升几分"
                style="width: 350px;margin-left: 80px"/>
          </div>
        </div>
        <div style="display: flex;width: 100%;margin-top: 20px">
          <div style="margin-left: 30px;display: flex">
            患者是否存在“感染”症状 :<a-radio-group
              style="margin-left: 20px"
              v-model="dataForm.template_content.resident_admit_note.infection_symptoms"
              :options="quest_infection_symptoms" />
          </div>
        </div>
        <div style="width: 100%;margin-top: 20px;">
          <div style="display: flex;margin-top: 10px">
            <div style="width: 200px;text-align: right">心率HR(次/分)</div>
            <div style="margin-left: 10px">
              <a-input
                  v-model="dataForm.template_content.resident_admit_note.hr"
                  style="width: 300px"
                  placeholder="请输入初始检查结果"
              />
            </div>
          </div>
          <div style="display: flex;margin-top: 10px">
            <div style="width: 200px;text-align: right">收缩压(mmHg)</div>
            <div style="margin-left: 10px">
              <a-input
                  v-model="dataForm.template_content.resident_admit_note.systolic_pressure"
                  style="width: 300px"
                  placeholder="请输入初始检查结果"
              />
            </div>
          </div>

          <div style="display: flex;margin-top: 10px">
            <div style="width: 200px;text-align: right">舒张压(mmHg)</div>
            <div style="margin-left: 10px">
              <a-input
                  v-model="dataForm.template_content.resident_admit_note.diastolic_pressure"
                  style="width: 300px"
                  placeholder="请输入初始检查结果"
              />
            </div>
          </div>

          <div style="display: flex;margin-top: 10px">
            <div style="width: 200px;text-align: right;">平均动脉压MAP(mmHg)</div>
            <div style="margin-left: 10px">
              <a-input
                  v-model="dataForm.template_content.resident_admit_note.arterial_pressure"
                  style="width: 300px"
                  placeholder="请输入初始检查结果"
              />
            </div>
          </div>

          <div style="display: flex;margin-top: 10px">
            <div style="width: 200px;text-align: right">体温T(℃)</div>
            <div style="margin-left: 10px">
              <a-input
                  v-model="dataForm.template_content.resident_admit_note.temperature"
                  style="width: 300px"
                  placeholder="请输入初始检查结果"
              />
            </div>
          </div>
          <div style="display: flex;margin-top: 10px">
            <div style="width: 200px;text-align: right">脉搏P（次/分)</div>
            <div style="margin-left: 10px">
              <a-input
                  v-model="dataForm.template_content.resident_admit_note.pulse"
                  style="width: 300px"
                  placeholder="请输入初始检查结果"
              />
            </div>
          </div>
          <div style="display: flex;margin-top: 10px">
            <div style="width: 200px;text-align: right">呼吸R（次/分)</div>
            <div style="margin-left: 10px">
              <a-input
                  v-model="dataForm.template_content.resident_admit_note.breathe"
                  style="width: 300px"
                  placeholder="请输入初始检查结果"
              />
            </div>
          </div>
          <div style="display: flex;margin-top: 10px">
            <div style="width: 200px;text-align: right">是否手术:</div>
            <div style="margin-left: 10px">
              <a-radio-group
                  v-model="dataForm.template_content.resident_admit_note.is_operation"
                  :options="quest_is_operation" />
            </div>
          </div>
          <div style="display: flex;margin-top: 10px">
            <div style="width: 200px;text-align: right">手术时⻓(分钟)</div>
            <div style="margin-left: 10px">
              <a-input
                  v-model="dataForm.template_content.resident_admit_note.operation_time"
                  style="width: 300px"
                  placeholder="请输入手术总时⻓分钟数"
              />
            </div>
          </div>
        </div>


        <div style="display: flex;width: 100%;margin-top: 20px">
          <div style="margin-left: 30px;display: flex">
            医嘱用药信息
          </div>
        </div>
        <div style="width: 100%;margin-top: 20px;">
          <div style="display: flex;margin-top: 10px">
            <div style="width: 200px;text-align: right">艾司洛尔使用时机:</div>
            <div style="margin-left: 10px">
              <a-input
                  v-model="dataForm.template_content.prescribed_medication.asle_use_timing"
                  style="width: 300px;"
                  placeholder="请输入使用总量(mg)"
              />
            </div>
          </div>
          <div style="display: flex;margin-top: 10px">
            <div style="width: 200px;text-align: right">是否使用升压药:</div>
            <div style="margin-left: 10px">
              <a-radio-group
                  v-model="dataForm.template_content.prescribed_medication.is_use_pressor"
                  :options="quest_is_use_pressor" />
            </div>
          </div>
          <div style="display: flex;margin-top: 10px">
            <div style="width: 200px;text-align: right">艾司洛尔使用起始时间:</div>
            <div style="margin-left: 10px">
              <a-date-picker
                  :allowClear="false"
                  v-model="dataForm.template_content.prescribed_medication.start_time"
                  style="width: 300px;"
                  placeholder="Select month"
                  @change="startChange" />
            </div>
          </div>
          <div style="display: flex;margin-top: 10px">
            <div style="width: 200px;text-align: right">艾司洛尔使用结束时间:</div>
            <div style="margin-left: 10px">
              <a-date-picker
                  :allowClear="false"
                  v-model="dataForm.template_content.prescribed_medication.end_time"
                  style="width: 300px;"
                  placeholder="Select month"
                  @change="endChange" />
            </div>
          </div>
          <div style="display: flex;margin-top: 10px">
            <div style="width: 200px;text-align: right">艾司洛尔使用方法:</div>
            <div style="margin-left: 10px">
              <a-radio-group
                  v-model="dataForm.template_content.prescribed_medication.asle_use_method"
                  :options="quest_asle_use_method" />
            </div>
          </div>
          <div style="display: flex;margin-top: 10px">
            <div style="width: 200px;text-align: right">艾司洛尔使用剂量范围(mg/h) :</div>
            <div style="margin-left: 10px;display: flex">
              <a-input
                  placeholder="请输入使用剂量范围"
                  v-model="partition.Using_the_total.small"
                  style="width: 148px"/>
              <span>-</span>
              <a-input
                  placeholder="请输入使用剂量范围"
                  v-model="partition.Using_the_total.big"
                  style="width: 148px"/>
            </div>
          </div>
          <div style="display: flex;margin-top: 10px">
            <div style="width: 200px;text-align: right">艾司洛尔使用总量(mg) :</div>
            <div style="margin-left: 10px">
              <a-input
                  v-model="dataForm.template_content.prescribed_medication.total"
                  style="width: 300px"
                  placeholder="请输入使用总量(mg)"
              />
            </div>
          </div>
        </div>
        <div style="display: flex;width: 100%;margin-top: 20px">
          <div style="margin-left: 30px;display: flex;margin-bottom: 20px">
            病程记录+化验结果信息
          </div>
        </div>
        <!--  血压 (mmHg) -->
        <div style="width: 95%;margin: auto;border: 1px solid #cbc9c9;margin-bottom: 20px">
          <div style="width: 90%;margin: auto;padding-top: 20px">
            <div style="border-left: 5px solid #868686;padding-left: 10px;font-weight: bold">血压 (mmHg)</div>
            <div style="padding-top: 10px;color: #8c8a8a;font-size: 12px">注:使用后24h、48h、76h、96h至少填写一个</div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用前24h</div>
              <div style="width: 50%;margin-left: 30px">使用时0h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_bp.one"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_bp.two"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后24h</div>
              <div style="width: 50%;margin-left: 30px">使用后48</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_bp.three"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_bp.four"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后72h</div>
              <div style="width: 50%;margin-left: 30px">使用后96h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_bp.five"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_bp.six"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
        </div>
        <!--  心率 (次/分) -->
        <div style="width: 95%;margin: auto;border: 1px solid #cbc9c9;margin-bottom: 20px">
          <div style="width: 90%;margin: auto;padding-top: 20px">
            <div style="border-left: 5px solid #868686;padding-left: 10px;font-weight: bold">心率 (次/分)</div>
            <div style="padding-top: 10px;color: #8c8a8a;font-size: 12px">注:使用后24h、48h、76h、96h至少填写一个</div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用前24h</div>
              <div style="width: 50%;margin-left: 30px">使用时0h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_hr.one"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_hr.two"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后24h</div>
              <div style="width: 50%;margin-left: 30px">使用后48</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_hr.three"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_hr.four"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后72h</div>
              <div style="width: 50%;margin-left: 30px">使用后96h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_hr.five"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_hr.six"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
        </div>
        <!--  体温 (°C) -->
        <div style="width: 95%;margin: auto;border: 1px solid #cbc9c9;margin-bottom: 20px">
          <div style="width: 90%;margin: auto;padding-top: 20px">
            <div style="border-left: 5px solid #868686;padding-left: 10px;font-weight: bold">体温 (°C)</div>
            <div style="padding-top: 10px;color: #8c8a8a;font-size: 12px">注:使用后24h、48h、76h、96h至少填写一个</div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用前24h</div>
              <div style="width: 50%;margin-left: 30px">使用时0h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_temperature.one"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_temperature.two"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后24h</div>
              <div style="width: 50%;margin-left: 30px">使用后48</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_temperature.three"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_temperature.four"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后72h</div>
              <div style="width: 50%;margin-left: 30px">使用后96h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_temperature.five"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_temperature.six"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
        </div>
        <!--  吸氧浓度及氧饱和度 (%) -->
        <div style="width: 95%;margin: auto;border: 1px solid #cbc9c9;margin-bottom: 20px">
          <div style="width: 90%;margin: auto;padding-top: 20px">
            <div style="border-left: 5px solid #868686;padding-left: 10px;font-weight: bold">吸氧浓度及氧饱和度 (%)</div>
            <div style="padding-top: 10px;color: #8c8a8a;font-size: 12px">注:使用后24h、48h、76h、96h至少填写一个</div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用前24h</div>
              <div style="width: 50%;margin-left: 30px">使用时0h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_xyndjybhd.one"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_xyndjybhd.two"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后24h</div>
              <div style="width: 50%;margin-left: 30px">使用后48</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_xyndjybhd.three"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_xyndjybhd.four"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后72h</div>
              <div style="width: 50%;margin-left: 30px">使用后96h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_xyndjybhd.five"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_xyndjybhd.six"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
        </div>
        <!--  CVP(mmHg) -->
        <div style="width: 95%;margin: auto;border: 1px solid #cbc9c9;margin-bottom: 20px">
          <div style="width: 90%;margin: auto;padding-top: 20px">
            <div style="border-left: 5px solid #868686;padding-left: 10px;font-weight: bold">CVP(mmHg)</div>
            <div style="padding-top: 10px;color: #8c8a8a;font-size: 12px">注:使用后24h、48h、76h、96h至少填写一个</div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用前24h</div>
              <div style="width: 50%;margin-left: 30px">使用时0h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_cvp.one"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_cvp.two"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后24h</div>
              <div style="width: 50%;margin-left: 30px">使用后48</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_cvp.three"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_cvp.four"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后72h</div>
              <div style="width: 50%;margin-left: 30px">使用后96h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_cvp.five"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_cvp.six"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
        </div>
        <!--APACHII(分)-->
        <div style="width: 95%;margin: auto;border: 1px solid #cbc9c9;margin-bottom: 20px">
          <div style="width: 90%;margin: auto;padding-top: 20px">
            <div style="border-left: 5px solid #868686;padding-left: 10px;font-weight: bold">APACHII(分)</div>
            <div style="padding-top: 10px;color: #8c8a8a;font-size: 12px">注:使用后24h、48h、76h、96h至少填写一个</div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用前24h</div>
              <div style="width: 50%;margin-left: 30px">使用时0h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_APACHII.one"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_APACHII.two"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后24h</div>
              <div style="width: 50%;margin-left: 30px">使用后48</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_APACHII.three"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_APACHII.four"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后72h</div>
              <div style="width: 50%;margin-left: 30px">使用后96h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_APACHII.five"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_APACHII.six"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
        </div>
        <!-- MAP(mmHg) -->
        <div style="width: 95%;margin: auto;border: 1px solid #cbc9c9;margin-bottom: 20px">
          <div style="width: 90%;margin: auto;padding-top: 20px">
            <div style="border-left: 5px solid #868686;padding-left: 10px;font-weight: bold">MAP(mmHg)</div>
            <div style="padding-top: 10px;color: #8c8a8a;font-size: 12px">注:使用后24h、48h、76h、96h至少填写一个</div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用前24h</div>
              <div style="width: 50%;margin-left: 30px">使用时0h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_MAP.one"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_MAP.two"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后24h</div>
              <div style="width: 50%;margin-left: 30px">使用后48</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_MAP.three"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_MAP.four"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后72h</div>
              <div style="width: 50%;margin-left: 30px">使用后96h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_MAP.five"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_MAP.six"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
        </div>
        <!-- PCT(ng/mL) -->
        <div style="width: 95%;margin: auto;border: 1px solid #cbc9c9;margin-bottom: 20px">
          <div style="width: 90%;margin: auto;padding-top: 20px">
            <div style="border-left: 5px solid #868686;padding-left: 10px;font-weight: bold">PCT(ng/mL)</div>
            <div style="padding-top: 10px;color: #8c8a8a;font-size: 12px">注:使用后24h、48h、76h、96h至少填写一个</div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用前24h</div>
              <div style="width: 50%;margin-left: 30px">使用时0h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_PCT.one"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_PCT.two"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后24h</div>
              <div style="width: 50%;margin-left: 30px">使用后48</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_PCT.three"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_PCT.four"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后72h</div>
              <div style="width: 50%;margin-left: 30px">使用后96h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_PCT.five"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_PCT.six"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
        </div>
        <!-- CRP(mg/L) -->
        <div style="width: 95%;margin: auto;border: 1px solid #cbc9c9;margin-bottom: 20px">
          <div style="width: 90%;margin: auto;padding-top: 20px">
            <div style="border-left: 5px solid #868686;padding-left: 10px;font-weight: bold">CRP(mg/L)</div>
            <div style="padding-top: 10px;color: #8c8a8a;font-size: 12px">注:使用后24h、48h、76h、96h至少填写一个</div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用前24h</div>
              <div style="width: 50%;margin-left: 30px">使用时0h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_CRP.one"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_CRP.two"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后24h</div>
              <div style="width: 50%;margin-left: 30px">使用后48</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_CRP.three"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_CRP.four"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后72h</div>
              <div style="width: 50%;margin-left: 30px">使用后96h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_CRP.five"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_CRP.six"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
        </div>
        <!-- 尿量(mL) -->
        <div style="width: 95%;margin: auto;border: 1px solid #cbc9c9;margin-bottom: 20px">
          <div style="width: 90%;margin: auto;padding-top: 20px">
            <div style="border-left: 5px solid #868686;padding-left: 10px;font-weight: bold">尿量(mL)</div>
            <div style="padding-top: 10px;color: #8c8a8a;font-size: 12px">注:使用后24h、48h、76h、96h至少填写一个</div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用前24h</div>
              <div style="width: 50%;margin-left: 30px">使用时0h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_nl.one"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_nl.two"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后24h</div>
              <div style="width: 50%;margin-left: 30px">使用后48</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_nl.three"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_nl.four"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后72h</div>
              <div style="width: 50%;margin-left: 30px">使用后96h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_nl.five"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_nl.six"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
        </div>
        <!-- 肌酐值(mg/dL) -->
        <div style="width: 95%;margin: auto;border: 1px solid #cbc9c9;margin-bottom: 20px">
          <div style="width: 90%;margin: auto;padding-top: 20px">
            <div style="border-left: 5px solid #868686;padding-left: 10px;font-weight: bold">肌酐值(mg/dL)</div>
            <div style="padding-top: 10px;color: #8c8a8a;font-size: 12px">注:使用后24h、48h、76h、96h至少填写一个</div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用前24h</div>
              <div style="width: 50%;margin-left: 30px">使用时0h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_jgz.one"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_jgz.two"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后24h</div>
              <div style="width: 50%;margin-left: 30px">使用后48</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_jgz.three"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_jgz.four"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后72h</div>
              <div style="width: 50%;margin-left: 30px">使用后96h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_jgz.five"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_jgz.six"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
        </div>
        <!-- 血管活性药物种类及剂量(μg/kg/min) -->
        <div style="width: 95%;margin: auto;border: 1px solid #cbc9c9;margin-bottom: 20px">
          <div style="width: 90%;margin: auto;padding-top: 20px">
            <div style="border-left: 5px solid #868686;padding-left: 10px;font-weight: bold">血管活性药物种类及剂量(μg/kg/min)</div>
            <div style="padding-top: 10px;color: #8c8a8a;font-size: 12px">注:使用后24h、48h、76h、96h至少填写一个</div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用前24h</div>
              <div style="width: 50%;margin-left: 30px">使用时0h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_xghxywzljjl.one"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_xghxywzljjl.two"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后24h</div>
              <div style="width: 50%;margin-left: 30px">使用后48</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_xghxywzljjl.three"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_xghxywzljjl.four"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后72h</div>
              <div style="width: 50%;margin-left: 30px">使用后96h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_xghxywzljjl.five"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_xghxywzljjl.six"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
        </div>
        <!-- Gap值 (mmHg) -->
        <div style="width: 95%;margin: auto;border: 1px solid #cbc9c9;margin-bottom: 20px">
          <div style="width: 90%;margin: auto;padding-top: 20px">
            <div style="border-left: 5px solid #868686;padding-left: 10px;font-weight: bold">Gap值 (mmHg)</div>
            <div style="padding-top: 10px;color: #8c8a8a;font-size: 12px">注:使用后24h、48h、76h、96h至少填写一个</div>
            <div style="padding-top: 10px;color: #8c8a8a;font-size: 12px;">Gap=静脉血二氧化碳分压-动脉血二氧化碳分压</div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用前24h</div>
              <div style="width: 50%;margin-left: 30px">使用时0h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_Gap.one"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_Gap.two"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后24h</div>
              <div style="width: 50%;margin-left: 30px">使用后48</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_Gap.three"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_Gap.four"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后72h</div>
              <div style="width: 50%;margin-left: 30px">使用后96h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_Gap.five"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_Gap.six"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
        </div>
        <!-- SV(mL) -->
        <div style="width: 95%;margin: auto;border: 1px solid #cbc9c9;margin-bottom: 20px">
          <div style="width: 90%;margin: auto;padding-top: 20px">
            <div style="border-left: 5px solid #868686;padding-left: 10px;font-weight: bold">SV(mL)</div>
            <div style="padding-top: 10px;color: #8c8a8a;font-size: 12px">注:使用后24h、48h、76h、96h至少填写一个</div>
            <div style="padding-top: 10px;color: #8c8a8a;font-size: 12px;">SV=心脏每搏输出量/心率*1000</div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用前24h</div>
              <div style="width: 50%;margin-left: 30px">使用时0h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_SV.one"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_SV.two"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后24h</div>
              <div style="width: 50%;margin-left: 30px">使用后48</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_SV.three"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_SV.four"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后72h</div>
              <div style="width: 50%;margin-left: 30px">使用后96h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_SV.five"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_SV.six"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
        </div>
        <!-- CI(L/(min·m2)) -->
        <div style="width: 95%;margin: auto;border: 1px solid #cbc9c9;margin-bottom: 20px">
          <div style="width: 90%;margin: auto;padding-top: 20px">
            <div style="border-left: 5px solid #868686;padding-left: 10px;font-weight: bold">CI(L/(min·m2))</div>
            <div style="padding-top: 10px;color: #8c8a8a;font-size: 12px">注:使用后24h、48h、76h、96h至少填写一个</div>
            <div style="padding-top: 10px;color: #8c8a8a;font-size: 12px;">注:CI=心脏每搏输出量x3600/身高(cm)*体重(kg)</div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用前24h</div>
              <div style="width: 50%;margin-left: 30px">使用时0h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_CI.one"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_CI.two"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后24h</div>
              <div style="width: 50%;margin-left: 30px">使用后48</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_CI.three"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_CI.four"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后72h</div>
              <div style="width: 50%;margin-left: 30px">使用后96h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_CI.five"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_CI.six"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
        </div>
        <!-- IL-6(pg/mL) -->
        <div style="width: 95%;margin: auto;border: 1px solid #cbc9c9;margin-bottom: 20px">
          <div style="width: 90%;margin: auto;padding-top: 20px">
            <div style="border-left: 5px solid #868686;padding-left: 10px;font-weight: bold">IL-6(pg/mL)</div>
            <div style="padding-top: 10px;color: #8c8a8a;font-size: 12px">注:使用后24h、48h、76h、96h至少填写一个</div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用前24h</div>
              <div style="width: 50%;margin-left: 30px">使用时0h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_IL.one"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_IL.two"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后24h</div>
              <div style="width: 50%;margin-left: 30px">使用后48</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_IL.three"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_IL.four"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
          <div style="width: 90%;margin: auto;margin-bottom: 15px">
            <div style="display: flex;margin-top: 10px">
              <div style="width: 50%">使用后72h</div>
              <div style="width: 50%;margin-left: 30px">使用后96h</div>
            </div>
            <div style="display: flex;margin-top: 10px">
              <a-input
                  v-model="partition.str_IL.five"
                  placeholder="请输入" style="width: 50%"/>
              <a-input
                  v-model="partition.str_IL.six"
                  placeholder="请输入" style="width: 50%;margin-left: 30px"/>
            </div>
          </div>
        </div>


        <div style="margin-top: 10px;margin-left: 30px;display: flex">
          <div>
            不良反应:
          </div>
          <div style="margin-left: 10px">
            <a-radio-group
                v-model="dataForm.template_content.progress_note.is_adverse_reaction"
                :options="quest_is_adverse_reaction" />
          </div>
        </div>
        <div style="margin-top: 10px;margin-left: 30px;margin-bottom: 20px">
          <a-input
              v-model="dataForm.template_content.progress_note.adverse_reaction_detail"
              placeholder="请描述具体情况及措施"
              style="width: 80%"/>
        </div>

        <div style="margin-top: 10px;margin-left: 30px;display: flex">
          <div>
            患者结局:
          </div>
        </div>
        <div style="margin-top: 10px;margin-left: 30px;display: flex">
          <div style="margin-left: 10px">
            <a-radio-group
                v-model="dataForm.template_content.progress_note.patient_transition"
                :options="questEnding_patient_transition" />
          </div>
        </div>
        <div style="margin-top: 10px;margin-left: 30px;display: flex">
          <div style="margin-left:10px">
            <a-date-picker
                v-show="dataForm.template_content.progress_note.patient_transition === '出院'"
                :allowClear="false"
                v-model="dataForm.template_content.progress_note.leave_icu_date"
                style="width: 100%;"
                placeholder="请选择出ICU时间"
                @change="startChangeICU" />
          </div>
          <div style="margin-left: 30px">
            <a-date-picker
                v-show="dataForm.template_content.progress_note.patient_transition === '出院'"
                :allowClear="false"
                v-model="dataForm.template_content.progress_note.leave_hospital_date"
                style="width: 100%;"
                placeholder="请选择出院时间"
                @change="startChange_hospital" />
          </div>
        </div>

        <div style="margin-top: 10px;margin-left: 30px;display: flex">
          <div style="margin-left: 10px">
            <a-radio-group
                v-model="dataForm.template_content.progress_note.patient_transition"
                :options="questEndingTwo_patient_transition" />
          </div>
        </div>
        <div style="margin-top: 10px;margin-left: 30px;display: flex;margin-bottom: 20px">
          <div style="margin-left:10px">
            <a-date-picker
                v-show="dataForm.template_content.progress_note.patient_transition === '死亡'"
                :allowClear="false"
                v-model="dataForm.template_content.progress_note.leave_icu_date"
                style="width: 100%;"
                placeholder="请选择出ICU时间"
                @change="startChange" />
          </div>
          <div style="margin-left: 30px">
            <a-date-picker
                v-show="dataForm.template_content.progress_note.patient_transition === '死亡'"
                :allowClear="false"
                v-model="dataForm.template_content.progress_note.death_date"
                style="width: 100%;"
                placeholder="请选择出院时间"
                @change="startChange" />
          </div>
        </div>

      </div>
    </div>
    <div style="height: 50px"></div>
    <div class="footer-div">
      <div class="footer-div-btn">
        <a-button @click="returnBtn">返回上一层</a-button>
        <a-button
            v-show="!passValue"
            style="margin-left: 10px"
            @click="unPassBtn">驳回</a-button>
        <a-button
            v-show="!passValue"
            @click="passBtn"
            style="margin-left: 10px"
            type="primary">通过</a-button>
      </div>
    </div>
    <!------------------------------------------------对话框----------------------------------------------------->
    <a-modal
        width="700px"
        v-model="visible"
        title="审批">
      <template slot="footer">
        <a-button @click="quxiaoModel">
          取消
        </a-button>
        <a-button type="primary"  @click="overruleModel">
          确定
        </a-button>
      </template>
      <div style="width: 100%;display: flex">
        <div style="width:20%;text-align: right;font-weight: bold;color: black">驳回原因 :</div>
        <a-checkbox-group
            v-model="checkboxVal"
            @change="checkbox_group_onChange"
            style="margin-left: 10px;width: 600px;">
          <a-row>
            <a-col style="margin-bottom: 8px" :span="24" v-for="(overRule,index) in overRules">
              <a-checkbox
                  :value="overRule">
                {{overRule}}
              </a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
      </div>
      <a-textarea
          style="width:500px;margin-left: 120px;margin-top: 10px;"
          v-model="overruleValue"
          placeholder="请输入其他驳回原因"
          :auto-size="{ minRows: 6, maxRows: 10 }"
      />
    </a-modal>
  </div>
</template>

<script>

import moment from "moment";
import {
  getCaseMessage,
  getCaseMessage_course,
  putCaseGo,
  putSex,
  TemplateIdRepeatHospitalNumber
} from "../../../service/case_api";

export default {
  data(){
    return{
      lenghtImage:'',
      checkboxVal:[],
      overRules:[],
      passBtnVal:true,
      dateFormatList:'YYYY-MM-DD',
      router_id:'',
      sexData:'',
      caseDetail:[],
      value:1,
      overruleValue:'',
      valueAge:20,
      visible:false,
      srcTest:'https://questionnaire.yunicu.com/#/esmolol/m',
      //入院记录性别
      quest_Sex : [
        { label: '男', value: '男' },
        { label: '女', value: '女' },
      ],
      questAnotherHospital : [
        { label: '否', value: 0 },
        { label: '是', value: 1 },
      ],
      //转院
      questBlood : [
        { label: '否', value: 0 },
        { label: '是', value: 1 },
      ],
      //脓毒症qSOFA是否予以评估
      quest_ndz_qsofa : [
        { label: '否', value: '否' },
        { label: '是', value: '是' },
      ],
      //SOFA是否予以评估
      quest_sofa : [
        { label: '否', value: '否' },
        { label: '是', value: '是' },
      ],
      //患者是否存在“感染”症状
      quest_infection_symptoms : [
        { label: '否', value: '否' },
        { label: '是', value: '是' },
      ],
      //是否手术
      quest_is_operation : [
        { label: '否', value: '否' },
        { label: '是', value: '是' },
      ],
      questChest  : [
        { label: '否', value: 0 },
        { label: '是', value: 1 },
      ],
      questOPS  : [
        { label: '否', value: 0 },
        { label: '是', value: 1 },
      ],
      quest_is_adverse_reaction  : [
        { label: '有', value: '有' },
        { label: '无', value:'无' },
      ],
      questEnding_patient_transition  : [
        { label: '出院', value: '出院' },
      ],
      questEndingTwo_patient_transition  : [
        { label: '死亡', value: '死亡' },
      ],
      quest_asle_use_method  : [
        { label: '持续不间断使用', value: '持续不间断使用' },
        { label: '持续间断使用', value: '持续间断使用' },
      ],
      //是否使用升压药
      quest_is_use_pressor  : [
        { label: '否', value: '否' },
        { label: '是', value: '是' },
      ],
      questisUse  : [
        { label: '否', value: 0 },
        { label: '是', value: 1 },
      ],
      questHuan  : [
        { label: '好转', value: 0 },
        { label: '未愈', value: 1 },
        { label: '死亡', value: 2 },
      ],
      SexPerson : [
        { label: '男', value: 1 },
        { label: '女', value:2 },
      ],

      //审核状态
      state:'',
      ageData:'',
      dataForm:{
        coding_image_list:[],
        "reject_reason":'',
        "template_content":{
          //病程记录+化验结果
          "progress_note": {},
          //入院记录
          "resident_admit_note": {},
          //医嘱用药
          "prescribed_medication": {}
        },
      },
      partition:{
        //脓毒症qSOFA是否予以评估
        // str_ndz_qsofa_detail:[],
        //使用总量
        Using_the_total:{
          small:'',
          big:'',
        },
        // "bp": "162/98-109/66-无-无-无-109/66",//血压
        str_bp:{
          one:'1',
          two:'2',
          three:'3',
          four:'4',
          five:'5',
          six:'6',
        },
        // "hr": "97-72-无-无-89-无",//心率
        str_hr:{
          one:'1',
          two:'2',
          three:'3',
          four:'4',
          five:'5',
          six:'6',
        },
        // "temperature": "37.1-36.1-无-无-38-无",//体温
        str_temperature:{
          one:'1',
          two:'2',
          three:'3',
          four:'4',
          five:'5',
          six:'6',
        },
        // "xyndjybhd": "97-72-无-无-89-无",//吸氧浓度及氧饱和度
        str_xyndjybhd:{
          one:'1',
          two:'2',
          three:'3',
          four:'4',
          five:'5',
          six:'6',
        },
        // "cvp": "97-72-无-无-89-无",//CVP
        str_cvp:{
          one:'1',
          two:'2',
          three:'3',
          four:'4',
          five:'5',
          six:'6',
        },
        // "APACHII": "97-72-无-无-89-无",//APACHII
        str_APACHII:{
          one:'1',
          two:'2',
          three:'3',
          four:'4',
          five:'5',
          six:'6',
        },
        // "MAP": "97-72-无-无-89-无",//MAP
        str_MAP:{
          one:'1',
          two:'2',
          three:'3',
          four:'4',
          five:'5',
          six:'6',
        },
        // "PCT": "97-72-无-无-89-无",//PCT
        str_PCT:{
          one:'1',
          two:'2',
          three:'3',
          four:'4',
          five:'5',
          six:'6',
        },
        // "CRP": "97-72-无-无-89-无",//CRP
        str_CRP:{
          one:'1',
          two:'2',
          three:'3',
          four:'4',
          five:'5',
          six:'6',
        },
        // "nl": "97-72-无-无-89-无",//尿量
        str_nl:{
          one:'1',
          two:'2',
          three:'3',
          four:'4',
          five:'5',
          six:'6',
        },
        // "jgz": "97-72-无-无-89-无",//肌酐值
        str_jgz:{
          one:'1',
          two:'2',
          three:'3',
          four:'4',
          five:'5',
          six:'6',
        },
        // "xghxywzljjl": "97-72-无-无-89-无",// 血管活性药物种类及剂量
        str_xghxywzljjl:{
          one:'1',
          two:'2',
          three:'3',
          four:'4',
          five:'5',
          six:'6',
        },
        // "Gap": "97-72-无-无-89-无",//Gap值
        str_Gap:{
          one:'1',
          two:'2',
          three:'3',
          four:'4',
          five:'5',
          six:'6',
        },
        // "SV": "97-72-无-无-89-无",//SV
        str_SV:{
          one:'1',
          two:'2',
          three:'3',
          four:'4',
          five:'5',
          six:'6',
        },
        // "CI": "97-72-无-无-89-无",//CI
        str_CI:{
          one:'1',
          two:'2',
          three:'3',
          four:'4',
          five:'5',
          six:'6',
        },
        // "IL": "97-72-无-无-89-无",//IL-6
        str_IL:{
          one:'1',
          two:'2',
          three:'3',
          four:'4',
          five:'5',
          six:'6',
        },
      }
    }
  },
  async created() {
    await this.getCase()
    await this.partitionData()
    await this.getCaseCourse()
  },
  mounted() {

  },
  computed:{
    passValue(){
      return this.$store.state.passBtn
    },
  },

  methods:{
    //获取数据
    async getCase() {
      let id = this.$route.query.id
      const response = await getCaseMessage(id)
      if(response.code === 0){
        this.dataForm = response.data
        this.state = response.data.audit_status
        this.overruleValue = response.data.reject_reason
        await this.partitionData()
        // if(this.state === 'PASSED' && this.passValue === ''){
        //   this.passBtnVal = false
        // }
      }else {
        this.$message.warning(response.message)
      }
      this.lenghtImage = this.dataForm.coding_image_list.length
    },
    //获取驳回原因
    async getCaseCourse() {
      let id = this.$route.query.templeteId
      const response = await getCaseMessage_course(id)
      if(response.code === 0){
        this.overRules = response.data.remark
      }else {
        this.$message.warning(response.message)
      }
    },
    //分割数据
    partitionData(){
      const form = this.dataForm

      //使用剂量范围
      const str_part = form.template_content.prescribed_medication.dose_range
      this.partition.Using_the_total.small = str_part.split('-')[0];
      this.partition.Using_the_total.big = str_part.split('-')[1];

      //血压BP(mmHg)
      const str_BP = form.template_content.progress_note.bp
      this.partition.str_bp.one = str_BP.split('-')[0];
      this.partition.str_bp.two = str_BP.split('-')[1];
      this.partition.str_bp.three = str_BP.split('-')[2];
      this.partition.str_bp.four = str_BP.split('-')[3];
      this.partition.str_bp.five = str_BP.split('-')[4];
      this.partition.str_bp.six = str_BP.split('-')[5];

      //心率
      const str_heart = form.template_content.progress_note.hr
      this.partition.str_hr.one = str_heart.split('-')[0];
      this.partition.str_hr.two = str_heart.split('-')[1];
      this.partition.str_hr.three = str_heart.split('-')[2];
      this.partition.str_hr.four = str_heart.split('-')[3];
      this.partition.str_hr.five = str_heart.split('-')[4];
      this.partition.str_hr.six = str_heart.split('-')[5];

      //体温
      const str_temperature = form.template_content.progress_note.temperature
      this.partition.str_temperature.one = str_temperature.split('-')[0];
      this.partition.str_temperature.two = str_temperature.split('-')[1];
      this.partition.str_temperature.three = str_temperature.split('-')[2];
      this.partition.str_temperature.four = str_temperature.split('-')[3];
      this.partition.str_temperature.five = str_temperature.split('-')[4];
      this.partition.str_temperature.six = str_temperature.split('-')[5];

      //吸氧浓度及氧饱和度
      const str_xyndjybhd = form.template_content.progress_note.xyndjybhd
      this.partition.str_xyndjybhd.one = str_xyndjybhd.split('-')[0];
      this.partition.str_xyndjybhd.two = str_xyndjybhd.split('-')[1];
      this.partition.str_xyndjybhd.three = str_xyndjybhd.split('-')[2];
      this.partition.str_xyndjybhd.four = str_xyndjybhd.split('-')[3];
      this.partition.str_xyndjybhd.five = str_xyndjybhd.split('-')[4];
      this.partition.str_xyndjybhd.six = str_xyndjybhd.split('-')[5];

      //cvp
      const str_cvp = form.template_content.progress_note.cvp
      this.partition.str_cvp.one = str_cvp.split('-')[0];
      this.partition.str_cvp.two = str_cvp.split('-')[1];
      this.partition.str_cvp.three = str_cvp.split('-')[2];
      this.partition.str_cvp.four = str_cvp.split('-')[3];
      this.partition.str_cvp.five = str_cvp.split('-')[4];
      this.partition.str_cvp.six = str_cvp.split('-')[5];

      //APACHII
      const str_APACHII = form.template_content.progress_note.APACHII
      this.partition.str_APACHII.one = str_APACHII.split('-')[0];
      this.partition.str_APACHII.two = str_APACHII.split('-')[1];
      this.partition.str_APACHII.three = str_APACHII.split('-')[2];
      this.partition.str_APACHII.four = str_APACHII.split('-')[3];
      this.partition.str_APACHII.five = str_APACHII.split('-')[4];
      this.partition.str_APACHII.six = str_APACHII.split('-')[5];

      //MAP
      const str_MAP = form.template_content.progress_note.MAP
      this.partition.str_MAP.one = str_MAP.split('-')[0];
      this.partition.str_MAP.two = str_MAP.split('-')[1];
      this.partition.str_MAP.three = str_MAP.split('-')[2];
      this.partition.str_MAP.four = str_MAP.split('-')[3];
      this.partition.str_MAP.five = str_MAP.split('-')[4];
      this.partition.str_MAP.six = str_MAP.split('-')[5];

      //MAP
      const str_PCT = form.template_content.progress_note.PCT
      this.partition.str_PCT.one = str_PCT.split('-')[0];
      this.partition.str_PCT.two = str_PCT.split('-')[1];
      this.partition.str_PCT.three = str_PCT.split('-')[2];
      this.partition.str_PCT.four = str_PCT.split('-')[3];
      this.partition.str_PCT.five = str_PCT.split('-')[4];
      this.partition.str_PCT.six = str_PCT.split('-')[5];

      //CRP
      const str_CRP = form.template_content.progress_note.CRP
      this.partition.str_CRP.one = str_CRP.split('-')[0];
      this.partition.str_CRP.two = str_CRP.split('-')[1];
      this.partition.str_CRP.three = str_CRP.split('-')[2];
      this.partition.str_CRP.four = str_CRP.split('-')[3];
      this.partition.str_CRP.five = str_CRP.split('-')[4];
      this.partition.str_CRP.six = str_CRP.split('-')[5];

      //nl
      const str_nl = form.template_content.progress_note.nl
      this.partition.str_nl.one = str_nl.split('-')[0];
      this.partition.str_nl.two = str_nl.split('-')[1];
      this.partition.str_nl.three = str_nl.split('-')[2];
      this.partition.str_nl.four = str_nl.split('-')[3];
      this.partition.str_nl.five = str_nl.split('-')[4];
      this.partition.str_nl.six = str_nl.split('-')[5];

      //jgz
      const str_jgz = form.template_content.progress_note.jgz
      this.partition.str_jgz.one = str_jgz.split('-')[0];
      this.partition.str_jgz.two = str_jgz.split('-')[1];
      this.partition.str_jgz.three = str_jgz.split('-')[2];
      this.partition.str_jgz.four = str_jgz.split('-')[3];
      this.partition.str_jgz.five = str_jgz.split('-')[4];
      this.partition.str_jgz.six = str_jgz.split('-')[5];

      //xghxywzljjl
      const str_xghxywzljjl = form.template_content.progress_note.xghxywzljjl
      this.partition.str_xghxywzljjl.one = str_xghxywzljjl.split('-')[0];
      this.partition.str_xghxywzljjl.two = str_xghxywzljjl.split('-')[1];
      this.partition.str_xghxywzljjl.three = str_xghxywzljjl.split('-')[2];
      this.partition.str_xghxywzljjl.four = str_xghxywzljjl.split('-')[3];
      this.partition.str_xghxywzljjl.five = str_xghxywzljjl.split('-')[4];
      this.partition.str_xghxywzljjl.six = str_xghxywzljjl.split('-')[5];

      //str_Gap
      const str_Gap = form.template_content.progress_note.Gap
      this.partition.str_Gap.one = str_Gap.split('-')[0];
      this.partition.str_Gap.two = str_Gap.split('-')[1];
      this.partition.str_Gap.three = str_Gap.split('-')[2];
      this.partition.str_Gap.four = str_Gap.split('-')[3];
      this.partition.str_Gap.five = str_Gap.split('-')[4];
      this.partition.str_Gap.six = str_Gap.split('-')[5];

      //str_SV
      const str_SV = form.template_content.progress_note.SV
      this.partition.str_SV.one = str_SV.split('-')[0];
      this.partition.str_SV.two = str_SV.split('-')[1];
      this.partition.str_SV.three = str_SV.split('-')[2];
      this.partition.str_SV.four = str_SV.split('-')[3];
      this.partition.str_SV.five = str_SV.split('-')[4];
      this.partition.str_SV.six = str_SV.split('-')[5];

      //str_CI
      const str_CI = form.template_content.progress_note.CI
      this.partition.str_CI.one = str_CI.split('-')[0];
      this.partition.str_CI.two = str_CI.split('-')[1];
      this.partition.str_CI.three = str_CI.split('-')[2];
      this.partition.str_CI.four = str_CI.split('-')[3];
      this.partition.str_CI.five = str_CI.split('-')[4];
      this.partition.str_CI.six = str_CI.split('-')[5];

      //str_IL
      const str_IL = form.template_content.progress_note.IL
      this.partition.str_IL.one = str_IL.split('-')[0];
      this.partition.str_IL.two = str_IL.split('-')[1];
      this.partition.str_IL.three = str_IL.split('-')[2];
      this.partition.str_IL.four = str_IL.split('-')[3];
      this.partition.str_IL.five = str_IL.split('-')[4];
      this.partition.str_IL.six = str_IL.split('-')[5];

    },
    //合并输入框的值
    sumForm(){
      let form = this.dataForm

      //使用剂量范围
      form.template_content.prescribed_medication.dose_range = this.partition.Using_the_total.small + '-' + this.partition.Using_the_total.big

      let progress_note = form.template_content.progress_note

      // "bp": "162/98-109/66-无-无-无-109/66",//血压
      progress_note.bp = this.partition.str_bp.one + '-' + this.partition.str_bp.two + '-' +
          this.partition.str_bp.three + '-' + this.partition.str_bp.four + '-' +
          this.partition.str_bp.five + '-' + this.partition.str_bp.six

      //     "hr": "97-72-无-无-89-无",//心率
      progress_note.hr = this.partition.str_hr.one + '-' + this.partition.str_hr.two + '-' +
          this.partition.str_hr.three + '-' + this.partition.str_hr.four + '-' +
          this.partition.str_hr.five + '-' + this.partition.str_hr.six

      //     "temperature": "37.1-36.1-无-无-38-无",//体温
      progress_note.temperature = this.partition.str_temperature.one + '-' + this.partition.str_temperature.two + '-' +
          this.partition.str_temperature.three + '-' + this.partition.str_temperature.four + '-' +
          this.partition.str_temperature.five + '-' + this.partition.str_temperature.six

      //     "xyndjybhd": "97-72-无-无-89-无",//吸氧浓度及氧饱和度
      progress_note.xyndjybhd = this.partition.str_xyndjybhd.one + '-' + this.partition.str_xyndjybhd.two + '-' +
          this.partition.str_xyndjybhd.three + '-' + this.partition.str_xyndjybhd.four + '-' +
          this.partition.str_xyndjybhd.five + '-' + this.partition.str_xyndjybhd.six

      //     "cvp": "97-72-无-无-89-无",//CVP
      progress_note.cvp = this.partition.str_cvp.one + '-' + this.partition.str_cvp.two + '-' +
          this.partition.str_cvp.three + '-' + this.partition.str_cvp.four + '-' +
          this.partition.str_cvp.five + '-' + this.partition.str_cvp.six

      //     "APACHII": "97-72-无-无-89-无",//APACHII
      progress_note.APACHII = this.partition.str_APACHII.one + '-' + this.partition.str_APACHII.two + '-' +
          this.partition.str_APACHII.three + '-' + this.partition.str_APACHII.four + '-' +
          this.partition.str_APACHII.five + '-' + this.partition.str_APACHII.six

      //     "MAP": "97-72-无-无-89-无",//MAP
      progress_note.MAP = this.partition.str_MAP.one + '-' + this.partition.str_MAP.two + '-' +
          this.partition.str_MAP.three + '-' + this.partition.str_MAP.four + '-' +
          this.partition.str_MAP.five + '-' + this.partition.str_MAP.six

      //     "PCT": "97-72-无-无-89-无",//PCT
      progress_note.PCT = this.partition.str_PCT.one + '-' + this.partition.str_PCT.two + '-' +
          this.partition.str_PCT.three + '-' + this.partition.str_PCT.four + '-' +
          this.partition.str_PCT.five + '-' + this.partition.str_PCT.six

      //     "CRP": "97-72-无-无-89-无",//CRP
      progress_note.CRP = this.partition.str_CRP.one + '-' + this.partition.str_CRP.two + '-' +
          this.partition.str_CRP.three + '-' + this.partition.str_CRP.four + '-' +
          this.partition.str_CRP.five + '-' + this.partition.str_CRP.six

      //     "nl": "97-72-无-无-89-无",//尿量
      progress_note.nl = this.partition.str_nl.one + '-' + this.partition.str_nl.two + '-' +
          this.partition.str_nl.three + '-' + this.partition.str_nl.four + '-' +
          this.partition.str_nl.five + '-' + this.partition.str_nl.six

      //     "jgz": "97-72-无-无-89-无",//肌酐值
      progress_note.jgz = this.partition.str_jgz.one + '-' + this.partition.str_jgz.two + '-' +
          this.partition.str_jgz.three + '-' + this.partition.str_jgz.four + '-' +
          this.partition.str_jgz.five + '-' + this.partition.str_jgz.six

      //     "xghxywzljjl": "97-72-无-无-89-无",// 血管活性药物种类及剂量
      progress_note.xghxywzljjl = this.partition.str_xghxywzljjl.one + '-' + this.partition.str_xghxywzljjl.two + '-' +
          this.partition.str_xghxywzljjl.three + '-' + this.partition.str_xghxywzljjl.four + '-' +
          this.partition.str_xghxywzljjl.five + '-' + this.partition.str_xghxywzljjl.six

      //     "Gap": "97-72-无-无-89-无",//Gap值
      progress_note.Gap = this.partition.str_Gap.one + '-' + this.partition.str_Gap.two + '-' +
          this.partition.str_Gap.three + '-' + this.partition.str_Gap.four + '-' +
          this.partition.str_Gap.five + '-' + this.partition.str_Gap.six

      //     "SV": "97-72-无-无-89-无",//SV
      progress_note.SV = this.partition.str_SV.one + '-' + this.partition.str_SV.two + '-' +
          this.partition.str_SV.three + '-' + this.partition.str_SV.four + '-' +
          this.partition.str_SV.five + '-' + this.partition.str_SV.six

      //     "CI": "97-72-无-无-89-无",//CI
      progress_note.CI = this.partition.str_CI.one + '-' + this.partition.str_CI.two + '-' +
          this.partition.str_CI.three + '-' + this.partition.str_CI.four + '-' +
          this.partition.str_CI.five + '-' + this.partition.str_CI.six

      //     "IL": "97-72-无-无-89-无",//IL-6
      progress_note.IL = this.partition.str_IL.one + '-' + this.partition.str_IL.two + '-' +
          this.partition.str_IL.three + '-' + this.partition.str_IL.four + '-' +
          this.partition.str_IL.five + '-' + this.partition.str_IL.six
    },
    async passBtn() {
      let form = this.dataForm
      //合并输入框的值
      await this.sumForm()
      //通过
      form.audit_status = "PASSED"
      let id = this.$route.query.id
      let templeteIdY = this.$route.query.templeteId
      const reponse = await putCaseGo(id,form,templeteIdY)
      if (reponse.code === 0) {
        this.$message.success('已通过！' + reponse.message)
        //刷新
        await this.getCase()
      } else {
        this.$message.error("失败" + reponse.message)
      }
    },

    //性别
    onChange(){

    },
    //返回上一层
    returnBtn() {
      window.history.back()
    },
    unPassBtn(){
      this.visible = true
    },
    //修改性别和年龄
    async putSex(sexData,ageData){
      const id = this.$route.query.id
      const data = {
        "patientSex":parseInt(sexData),
        "birthday":ageData
      }
      const reponce = await putSex(id,data)
      if(reponce.code === 0){
        this.$message.success('修改成功！'+reponce.message)
        await this.getCase()
      }else {
        this.$message.warning(reponce.message)
      }
    },
    // 保存患者信息
    preservation(){
      const sex = parseInt(this.dataForm.patientSex)
      const age = this.ageData
      if(sex!== '' && sex !== null && sex !== undefined ||
          age!== '' && age !== null && age !== undefined){
        switch (sex){
          case 1:
            this.putSex(1,age)
            break;
          case 2:
            this.putSex(2,age)
            break;
          default:
            break;
        }
      }
    },
    //出生日期
    ageChange(date){
      const dataTime = moment(date).format('YYYY-MM-DD')
      this.ageData = dataTime
    },
    //入院选择
    hospitalizedChange(date){
      this.dataForm.template_content.resident_admit_note.admission_time = moment(date).format('YYYY-MM-DD')
    },
    operationChange(date){
      this.dataForm.template_content.resident_admit_note.operation_time = moment(date).format('YYYY-MM-DD')
    },
    startChange(date){
      this.dataForm.template_content.prescribed_medication.start_time = moment(date).format('YYYY-MM-DD')
    },
    startChangeICU(date){
      this.dataForm.template_content.progress_note.leave_icu_date = moment(date).format('YYYY-MM-DD')
    },
    endChange(date){
      this.dataForm.template_content.prescribed_medication.end_time = moment(date).format('YYYY-MM-DD')
    },
    startChange_hospital(date){
      this.dataForm.template_content.progress_note.leave_hospital_date = moment(date).format('YYYY-MM-DD')
    },
    checkboxChange(checkedValues){
    },
    useChange(){

    },
    finishChange(){

    },
    quxiaoModel() {
      this.visible = false;
    },
    checkbox_group_onChange(){
      this.overruleValue = this.checkboxVal.join(';')
    },
    //驳回确定按钮
    async overruleModel() {
      let form = this.dataForm
      //合并输入框的值
      await this.sumForm()
      let id = this.$route.query.id
      let templeteIdY = this.$route.query.templeteId
      form.audit_status = "DENIED"
      form.reject_reason = this.overruleValue
      const reponse = await putCaseGo(id, form,templeteIdY)
      if (reponse.code === 0) {
        this.$message.success('已驳回！' + reponse.message)
      } else {
        this.$message.error("失败，请联系管理员！！" + reponse.message)
      }
      this.visible = false
    },
    //  图片刷新
    refreshBtn(){
      this.getCase()
    },
    //  打码
    captchaBtn(){
      const userData = JSON.parse(window.localStorage.getItem("user_info"))
      // window.open(`${this.adminLink}`+ '?id=' + row.id + '&user_id=' + userData.id)
      let id = this.$route.query.id
      this.$router.push({ path: '/caseCode',query:{id:id,userId:userData.id,userName:userData.user_name}})
    },
    async examineBtn(id,member) {
      const reponse = await TemplateIdRepeatHospitalNumber(id,member)
      if (reponse.code === 0) {
        this.$message.success(reponse.message)
      } else {
        this.$message.error(reponse.message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.review-title{
  font-size: 22px;
  font-weight: bold;
  color: black;
  height:100px;
  line-height: 80px;
  padding-left: 30px;
  border-bottom: 10px solid #f5f5f5;
}
.review-doc{
  height:120px;
  line-height: 50px;
  border-bottom: 10px solid #f5f5f5;
  color: black;
  .review-doc-title{
    font-size: 15px;
    color: black;
    font-weight: bold;
    padding-left: 30px;
    border-bottom: 2px solid #f5f5f5;
  }
  .review-doc-content{
    width: 100%;
    display: flex;
    color: black;
    .review-doc-content-font{
      width: 30%;
      padding-left: 30px;
      color: black;
    }
  }
}

.review-huan{
  height:150px;
  line-height: 50px;
  border-bottom: 10px solid #f5f5f5;
  color: black;
  .review-huan-title{
    padding-left: 30px;
    border-bottom: 2px solid #f5f5f5;
    display: flex;
    color: black;
    .review-huan-title-huanzhe{
      font-size: 15px;
      color: black;
      font-weight: bold;
      width: 85%;
    }
  }
  .review-huan-content{
    width: 100%;
    display: flex;
    color: black;
    .review-huan-content-font{
      width: 30%;
      padding-left: 30px;
      padding-top: 15px;
      display: flex;
      color: black;
    }
    .review-huan-content-font-two{
      display: flex;
      width: 30%;
      padding-left: 30px;
      padding-top: 15px;
      color: black;
    }
  }
}
.review-content{
  height: auto;
  display: flex;
  //border-bottom: 10px solid #f5f5f5;
  color: black;
  .review-content-left{
    width: 50%;
    //height: 100%;
    min-height: 2000px;
    color: black;
    .review-content-left-title{
      font-size: 16px;
      height: 60px;
      line-height: 60px;
      color: black;
      font-weight: bold;
      padding-left: 30px;
      border-bottom: 2px solid #f5f5f5;
    }
    .review-content-left-font{
      font-weight: bold;
      padding-left: 30px;
      color: black;
      margin-bottom: 15px;
    }
  }
  .review-content-right{
    width: 50%;
    height: auto;
    color: black;
    border-left: 10px solid #f5f5f5;
    .review-content-right-title{
      font-size: 16px;
      height: 60px;
      line-height: 60px;
      color: black;
      font-weight: bold;
      padding-left: 30px;
      border-bottom: 2px solid #f5f5f5;
    }
    .review-content-right-title-title{
      font-weight: bold;
      padding-left: 30px;
      margin-top: 30px;
      color: black;
    }
    .review-content-right-person{
      display: flex;
      margin-bottom: 15px;
      width: 100%;
      margin-left:80px;
      color: black;
      .review-content-right-person-name{
        width: 50%;
        margin-left: 5%;
        color: black;
      }
      .review-content-right-person-sex{
        width: 50%;
      }
    }
    .toBeMessage{
      margin-bottom: 15px;
      display: flex;
      width: 100%;
      .font{
        width: 50%;
        color: black;
      }
      .selectBtn{
        width: 50%;
        color: black;
      }
    }
    .betoMessage{
      margin-bottom: 15px;
      display: flex;
      width: 100%;
      .font{
        width: 150px;
        text-align: right;
        color: black;
      }
      .inputShu{
        width: 60%;
        margin-left: 5px;
        color: black;
      }
    }
    .mazui{
      margin-bottom: 15px;
      width: 100%;
      .font{
        margin-left: 5%;
        color: black;
      }
    }
    .mazui-input{
      margin-bottom: 15px;
      display: flex;
      width: 100%;
      color: black;
      .mazui-input-one{
        width: 24%;
        text-align: center;
        color: black;
      }
    }
    .inputWitch{
      width: 60%;
      color: black;
    }
  }
}
.footer-div{
  width: 100%;
  height: 50px;
  background-color: #f0f2f5;
  position: fixed;
  bottom: 50px;
  border-bottom: 1px solid #fff;
  .footer-div-btn{
    display: flex;justify-content: right;
    width: 82%;
    margin-top: 8px;
  }
}
</style>